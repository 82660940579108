<template>
  <div class="MsgAdmin todo">
    <div class="page-header flex">
      <span style="font-size:16px;">消息管理</span>
    </div>
    <div style="padding: 20px;">
      <div class="header-list">
        <ul>
          <li>
            <div class="flex">
              <span>队列中</span>
              <span class="num">{{ statistics.QueueCount }}</span>
            </div>
            <div>
              <img src="../../assets/images/msg-admin-1.png" alt="" />
            </div>
          </li>

          <li>
            <div class="flex">
              <span>成功</span>
              <span class="num">{{ statistics.SuccessCount }}</span>
            </div>
            <div>
              <img src="../../assets/images/msg-admin-2.png" alt="" />
            </div>
          </li>

          <li style="margin-right: 0">
            <div class="flex">
              <span>失败</span>
              <span class="num">{{ statistics.FailCount }}</span>
            </div>
            <div>
              <img src="../../assets/images/msg-admin-3.png" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <div class="screen-list flex" style="margin-top: 40px;">
        <div class="screen-list-item flex">
          <span>标题</span>
          <a-input
            placeholder="请输入标题"
            style="flex: 1;height: 36px;"
            v-model="Keyword"
          />
        </div>
        <div class="screen-list-item flex">
          <span>消息ID</span>
          <a-input
            placeholder="请输入消息ID"
            style="flex: 1;height: 36px;"
            v-model="MsgId"
          />
        </div>
        <div class="screen-list-item flex">
          <span>消息类型</span>
          <!-- <a-input placeholder="请输入关键字" style="flex: 1;height: 36px;" v-model="Keyword"/> -->
          <a-select style="width: 140px;flex:1" v-model="MsgType">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">立即消息</a-select-option>
            <a-select-option value="2">定时消息</a-select-option>
          </a-select>
        </div>

        <div class="screen-list-item flex">
          <span>是否重复</span>
          <!-- <a-input placeholder="请输入关键字" style="flex: 1;height: 36px;" v-model="Keyword"/> -->
          <a-select style="width: 140px;flex:1" v-model="isRepeat">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="0">否</a-select-option>
            <a-select-option value="1">是</a-select-option>
          </a-select>
        </div>

        <div class="screen-list-item flex">
          <div class="flex" style="flex:1">
            <span class="screen-item-label">发送人</span>
            <a-tree-select
              style="flex:1"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择"
              allowClear
              treeDefaultExpandAll
            >
            </a-tree-select>
          </div>
        </div>
        <div class="screen-list-item flex" style="margin-right:0">
          <span class="screen-item-label">发生时间</span>
          <a-range-picker
            style="flex: 1"
            format="YYYY-MM-DD"
            :placeholder="['开始时间', '结束时间']"
            allowClear
            @change="_createdDate"
          />
        </div>
      </div>

      <div
        class="flex"
        style="text-align: right;margin: 15px  0;padding: 5px 0;"
      >
        <div>
          <span class="screen-item-label">发送状态</span>
          <span style="margin-left: 10px;">
            <a-button
              :type="State === '' ? 'primary' : 'default'"
              class="op-btn"
              @click="_getStatus('')"
              >全部</a-button
            >
            <a-button
              :type="State === 0 ? 'primary' : 'default'"
              class="op-btn"
              @click="_getStatus(0)"
              >队列中</a-button
            >
            <a-button
              :type="State === 1 ? 'primary' : 'default'"
              class="op-btn"
              @click="_getStatus(1)"
              >成功</a-button
            >
            <a-button
              :type="State === 2 ? 'primary' : 'default'"
              class="op-btn"
              @click="_getStatus(2)"
              >失败</a-button
            >

            <a-button type="default" @click="_showVisible()" danger
              >模拟发送</a-button
            >
          </span>
        </div>
        <div>
          <a-button
            style="width: 110px;height: 34px;margin-right: 20px"
            @click="_reset()"
            >重置条件</a-button
          >
          <a-button
            type="primary"
            style="width: 110px;height: 34px;"
            @click="_searchBtn"
            >开始查询</a-button
          >
        </div>
      </div>

      <div style="margin-top: 20px;">
        <a-table
          :columns="columns"
          :dataSource="infoData"
          :pagination="false"
          bordered
          v-if="infoData"
        >
          <div
            slot="Pid"
            slot-scope="text, record"
            class="ellipsis"
            :title="record.Pid"
            @click="_copyUrl($event)"
          >
            {{ record.Pid }}
          </div>
          <div
            slot="MsgId"
            slot-scope="text, record"
            class="ellipsis"
            :title="record.MsgId"
            @click="_copyUrl($event)"
          >
            {{ record.MsgId }}
          </div>
          <div
            slot="NotifyUrl"
            slot-scope="text, record"
            class="ellipsis"
            :title="record.NotifyUrl"
            @click="_copyUrl($event)"
          >
            {{ record.NotifyUrl }}
          </div>

          <div
            slot="Title"
            slot-scope="text, record"
            class="ellipsis"
            :title="record.Title"
            @click="_copyUrl($event)"
          >
            {{ record.Title }}
          </div>
          <div
            slot="SendTime"
            slot-scope="text, record"
            class="ellipsis"
            :title="record.SendTime"
          >
            {{ record.SendTime }}
          </div>
          <div
            slot="UserName"
            slot-scope="text, record"
            class="ellipsis"
            :title="record.UserName"
          >
            {{ record.UserName }}
          </div>
          <div
            slot="Msg"
            slot-scope="text, record"
            class="ellipsis"
            :title="record.Msg"
            @click="_copyUrl($event)"
          >
            {{ record.Msg }}
          </div>
        </a-table>
      </div>

      <div style="margin: 20px 0;text-align: left">
        <a-pagination
          showQuickJumper
          :defaultCurrent="1"
          :total="pageTotal"
          @change="_PageChange"
        />
      </div>
    </div>

    <a-drawer
      title="模拟发送"
      placement="right"
      :closable="false"
      width="900"
      @close="_hideCancel"
      :visible="visible"
    >
      <a-form class="coupon_form">
        <a-form-item label="标题" style="width:260px">
          <a-input v-model="T_Title" placeholder="请输入标题" />
        </a-form-item>

        <a-form-item label="内容">
          <a-input
            v-model="T_Data"
            placeholder="请输入json或者字符（不允许为空）"
          />
        </a-form-item>

        <a-form-item label="回调地址">
          <a-input
            v-model="T_NotifyUrl"
            placeholder="请输入回调地址（不允许为空）"
          />
        </a-form-item>

        <a-form-item label="消息类型" style="width:260px">
          <a-select
            placeholder="请选择"
            defaultValue="立即消息"
            @change="handleChange('T_MsgType', $event)"
          >
            <a-select-option value="1">立即消息</a-select-option>
            <a-select-option value="2">定时消息</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="发送时间" style="width:260px">
          <!-- <a-input v-model="T_SendTime" placeholder="消息类型为定时消息时填写" value='0' /> -->
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            v-model="startValue"
            placeholder="发送时间"
            @ok="onChangePicker"
          />
        </a-form-item>

        <a-form-item label="是否重复" style="width:260px">
          <a-select
            placeholder="请选择"
            defaultValue="否"
            @change="handleChange('T_isRepeat', $event)"
          >
            <a-select-option value="0">否</a-select-option>
            <a-select-option value="1">是</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="模拟消息数量（<=10000）" style="width:260px">
          <a-input v-model="T_Num" placeholder="模拟消息数量，默认0" />
        </a-form-item>

        <a-button
          :style="{ marginRight: '8px', width: '96px' }"
          @click="_hideCancel"
          >取消</a-button
        >
        <a-button style="width:'96px'" @click="subBtn()" type="primary"
          >提 交</a-button
        >
      </a-form>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: 'MsgAdmin',
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          key: 'sNumber',
          align: 'center'
        },
        {
          title: '消息类型',
          dataIndex: 'MsgType',
          key: 'MsgType',
          align: 'center'
        },
        { title: 'PID', align: 'center', scopedSlots: { customRender: 'Pid' } },
        {
          title: '消息ID',
          align: 'center',
          scopedSlots: { customRender: 'MsgId' }
        },
        {
          title: '标题',
          align: 'center',
          scopedSlots: { customRender: 'Title' }
        },
        {
          title: '回调地址',
          align: 'center',
          scopedSlots: { customRender: 'NotifyUrl' }
        },
        {
          title: '是否重复',
          dataIndex: 'isRepeat',
          key: 'isRepeat',
          align: 'center'
        },
        {
          title: '重复次数',
          dataIndex: 'RepeatNum',
          key: 'RepeatNum',
          align: 'center'
        },
        {
          title: '成功次数',
          dataIndex: 'SuccessCount',
          key: 'SuccessCount',
          align: 'center'
        },
        {
          title: '失败次数',
          dataIndex: 'FailedCount',
          key: 'FailedCount',
          align: 'center'
        },
        {
          title: '发送人',
          align: 'center',
          scopedSlots: { customRender: 'UserName' }
        },
        {
          title: '发生时间',
          align: 'center',
          scopedSlots: { customRender: 'SendTime' }
        },
        { title: '信息', scopedSlots: { customRender: 'Msg' } }
      ],
      infoData: [],
      pageTotal: -1,
      page: 1,
      statistics: '',
      Keyword: '',
      startEndDate: '',
      startDate: '',
      endDate: '',
      State: '',
      MsgType: '',
      isRepeat: '',
      visible: false,
      MsgId: '',
      startValue: null,
      endValue: null,
      endOpen: false,

      T_Title: '',
      T_Data: '',
      T_NotifyUrl: '',
      T_MsgType: '',
      T_SendTime: '',
      T_isRepeat: '',
      T_Num: 0
    }
  },
  created () {
    this.$message.loading('加载中...')
    this.$axios.get(
      1220,
      {
        Keyword: '',
        UserId: '',
        State: '',
        startDate: '',
        endDate: '',
        Page: ''
      },
      res => {
        this.$message.destroy()
        if (res.data.code == 1) {
          this.infoData = res.data.data
          this.pageTotal = res.data.Count
          this.statistics = res.data.ChartData
        }
      }
    )
  },
  watch: {
    startValue (val) {
      if (val) {
        this.T_SendTime = val.format('X')
      }
    }
  },
  methods: {
    _searchBtn () {
      this._info(1)
    },
    _PageChange (pageNumber) {
      this.page = pageNumber
      this._info(pageNumber)
    },
    _getStatus (i) {
      this.State = i
      this._info(1)
    },
    _info (page) {
      this.$message.loading('加载中...')
      this.infoData = []
      let data = {
        Keyword: this.Keyword,
        UserId: '',
        State: this.State,
        startDate: this.startDate,
        endDate: this.endDate,
        Page: page,
        MsgType: this.MsgType,
        isRepeat: this.isRepeat,
        MsgId: this.MsgId
      }
      this.$axios.get(1220, data, res => {
        this.$message.destroy()
        if (res.data.code == 1) {
          this.infoData = res.data.data
          this.pageTotal = res.data.Count
          this.statistics = res.data.ChartData
        } else {
          this.infoData = []
          this.pageTotal = 0
          this.statistics = ''
        }
      })
    },
    _createdDate (value) {
      this.startEndDate = value
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
    },
    _reset () {
      this.Keyword = ''
      this.startEndDate = ''
      this.endDate = ''
      this.State = ''
      this.MsgType = ''
      this.isRepeat = ''
      this.MsgId = ''
    },
    _hideCancel () {
      this.visible = false
      this.T_Title = ''
      this.T_Data = ''
      this.T_NotifyUrl = ''
      this.T_MsgType = ''
      this.T_SendTime = ''
      this.T_isRepeat = ''
      this.T_Num = 0
    },
    _showVisible () {
      this.visible = true
    },
    onClose_drawer () {
      this.visible = false
    },
    subBtn () {
      if (this.T_Title == '') {
        this.$message.error('标题必填！')
        return false
      }

      if (this.T_Data == '') {
        this.$message.error('内容必填！')
        return false
      }

      if (this.T_NotifyUrl == '') {
        this.$message.error('回调地址必填！')
        return false
      }

      if (this.T_Num > 10000) {
        this.$message.error('数量不允许超过10000')
        return false
      }
      let user = JSON.parse(window.sessionStorage.getItem('userInfo'))
      let data = {
        UserId: user.uid,
        UserName: user.name,
        Title: this.T_Title,
        Data: this.T_Data,
        NotifyUrl: this.T_NotifyUrl,
        MsgType: this.T_MsgType ? this.T_MsgType : 1,
        SendTime: this.T_SendTime,
        isRepeat: this.T_isRepeat ? this.T_isRepeat : 0,
        Num: this.T_Num
      }

      let url = ''
      if (this.T_Num > 1) {
        url = 'https://eq.tpiedu.net/e/producer/batchtask'
      } else {
        url = 'https://eq.tpiedu.net/e/producer/entry'
      }

      const hide = this.$message.loading('提交中...', 0)
      let self = this
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        dataType: 'json',
        success: function (data) {
          console.log(data)
          if (data.code == 1) {
            self.onClose_drawer()
            self.$message.success('success')
            location.reload()
          } else {
            self.$message.error(data.msg)
          }
        }
      })
      setTimeout(hide, 1500)
    },
    handleChange (c, v) {
      switch (c) {
        case 'T_MsgType':
          this.T_MsgType = v
          break
        case 'T_isRepeat':
          this.T_isRepeat = v
          break
        default:
      }
    },
    onChangePicker (value) {
      this.T_SendTime = value.format('X')
    },
    _copyUrl (obj) {
      let self = this
      let oInput = document.createElement('input')
      oInput.value = obj.target.innerText
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy') // 执行浏览器复制命令
      self.$message.success('复制成功')
      oInput.remove()
    }
  }
}
</script>

<style scoped lang="less">
.MsgAdmin {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  font-size: 14px;
  .page-header {
    height: 55px;
    line-height: 55px;
    padding: 10px 20px;
    border-bottom: 1px solid #e1e2e3;
  }
  .header-list {
    ul {
      display: flex;
      li {
        padding: 20px;
        border: 1px solid #e8e8e8;
        margin-right: 20px;
        flex: 1;
        border-radius: 4px;
        span {
          font-weight: 500;
          color: #131313;
          font-size: 17px;
        }
        .num {
          font-size: 24px;
        }
        img {
          margin-top: 15px;
          width: 100%;
        }
      }
    }
  }
  .screen-list {
    margin-top: 10px;
    .screen-list-item {
      margin-right: 20px;
      flex: 1;
    }
    .screen-item-label {
      margin-right: 10px;
      color: #666;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .op-btn {
    width: 80px !important;
    height: 34px !important;
    margin-right: 15px !important;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 110px;
  }
}
</style>
